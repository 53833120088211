import React, { useEffect } from "react";
import pluralize from "pluralize";

import { retrieveCart } from "api/requests/retrieveCart";
import { Action, Category } from "constants/events";
import useDomainCountryCode from "hooks/useDomainCountryCode";
import { useEventTracker } from "hooks/useEventTracker";
import { useDispatch, useSelector } from "redux/hooks";
import { GET_CART_SUCCESS, GetCartSuccessAction } from "redux/reducers/cart";
import { getCartSessionId, getTotalCartQuantity } from "redux/selectors/cart";

import CartIcon from "components/Icons/cart.svg";

import AddToBagPopOver from "./AddToBagPopover";

const Cart = () => {
  const { publishEvent } = useEventTracker();
  const dispatch = useDispatch();

  const domainCountryCode = useDomainCountryCode();
  const cartItemCount = useSelector(getTotalCartQuantity);
  const cartSessionId = useSelector(getCartSessionId);

  useEffect(() => {
    // Cart will initially be undefined, so cartItemCount will initially be undefined
    if (cartItemCount === undefined && cartSessionId) {
      retrieveCart({
        cartId: cartSessionId,
        domainCountryCode,
      }).then((response) => {
        if (response.cart) {
          dispatch<GetCartSuccessAction>({
            cart: response.cart,
            changes: response.changes || [],
            type: GET_CART_SUCCESS,
          });
        }
      });
    }
  }, [cartItemCount, cartSessionId]);

  return (
    <nav className="flex-shrink-0 relative ml-lg" aria-label="Cart">
      {/* TODO: Can be converted to next/link once /bag uses getServersideProps */}
      <a
        data-testid="cart-icon-link"
        href="/bag"
        className="flex items-center relative"
        aria-label={`Shopping bag. ${pluralize(
          "item",
          cartItemCount ?? 0,
          true,
        )} in cart`}
        onClick={() =>
          publishEvent({
            action: Action.click,
            category: Category.header,
            label: "cart",
          })
        }
      >
        <CartIcon height="24" width="24" />
        <div
          className="absolute flex items-center justify-center leading-none opacity-100 pointer-events-none rounded-full text-white text-xs bg-green"
          style={{
            minHeight: "20px",
            minWidth: "20px",
            opacity: cartItemCount ? undefined : "0",
            right: "-12px",
            top: "-8px",
            transition: "opacity 250ms linear",
          }}
        >
          {cartItemCount}
        </div>
      </a>
      <AddToBagPopOver />
    </nav>
  );
};

export default Cart;
