import {
  getCookie as getClientCookie,
  setCookie as setClientCookie,
} from "cookies-next/client";
import {
  getCookie as getServerCookie,
  setCookie as setServerCookie,
} from "cookies-next/server";
import { IncomingHttpHeaders, IncomingMessage } from "http";
import { GetServerSidePropsContext } from "next";
import { v4 as uuidv4 } from "uuid";

import { decode, encode } from "utils/base64";

type OptionsType =
  | Parameters<typeof getClientCookie>[1]
  | GetServerSidePropsContext;

const GOOGLEBOT_USER_KEY = "Googlebot";
const GOOGLEBOT_USER_AGENT_PATTERN =
  /APIs-Google|AdsBot-Google-Mobile|AdsBot-Google|Googlebot/;

export const SPLIT_CONSUMER_COOKIE_NAME = "leafly.browser.session";

export const getSplitKey = async (context?: OptionsType): Promise<string> => {
  if (typeof window === "undefined" && !context) {
    /**
     * This path should never be reached in normal operation, but needs to
     * exist in order to support the use of `useClientSideSplitTreatments`.
     * This hook is invoked in both server-side and client-side rendering,
     * but only ever uses the split key after the page has been hydrated on
     * the client. However, we still need to pass in a string value for the
     * key during server-side rendering of the component where we don't have
     * access to the cookie values. Given that `useClientSideSplitTreatments`
     * doesn't use this value during SSR, we can pass a blank value in the
     * case where we're calling this function in component in SSR.
     *
     * In the case that we're calling this function in data fetching methods
     * there should be no reason to omit the context argument, and we can still
     * throw an error to guide engineers towards using the appropriate argument.
     */
    if (calledDuringDataFetching()) {
      throw new TypeError(
        "Next.js context must be provided when getting the split key on the server",
      );
    } else {
      return "";
    }
  }

  const getCookieFn = context ? getServerCookie : getClientCookie;
  const splitCookie = await getCookieFn(SPLIT_CONSUMER_COOKIE_NAME, context);

  if (splitCookie) {
    try {
      const { splitKey } = decode(String(splitCookie));

      if (splitKey) {
        return String(splitKey);
      }
    } catch {
      /**
       * If we see an error here, it's because JSON parsing failed and
       * some invalid value was in the cookie. If this happens, we'll keep
       * going and assign a new key below.
       */
    }
  }

  const userAgent =
    (context?.req?.headers as IncomingHttpHeaders)?.["user-agent"] ||
    (typeof navigator !== "undefined" && navigator.userAgent);
  const ip =
    (context?.req?.headers as IncomingHttpHeaders)?.["x-forwarded-for"] ||
    (context?.req as IncomingMessage)?.socket?.remoteAddress;

  let splitKey: string;
  if (userAgent && GOOGLEBOT_USER_AGENT_PATTERN.test(userAgent)) {
    splitKey = GOOGLEBOT_USER_KEY;
  } else if (typeof window === "undefined" && ip) {
    /**
     * Use a dynamic import and checking for the window global allows us to
     * avoid including the node-only md5 package in our client bundle.
     * This means that this function will return a different value when run
     * client-side, but the goal of this code is to reduce the number of
     * keys that we issue by using a consistent base value when available.
     * This should only impact the rare case when a new session has not been
     * issued a key server-side and then encounters an instance of this
     * method called client-side.
     */
    splitKey = (await import("md5")).default(`${ip} ${userAgent}`);
  } else {
    splitKey = uuidv4();
  }

  setSplitCookie(splitKey, context);

  return splitKey;
};

const calledDuringDataFetching = (): boolean => {
  try {
    throw new Error();
  } catch (e) {
    return !!String(e.stack).match(
      /(getInitialProps|getServerSideProps|getStaticProps)/,
    );
  }
};

const setSplitCookie = (splitKey: string, context?: OptionsType) => {
  const url = context ? context.req?.url : window.location.host;
  const domain = url?.substring(url.indexOf(".leafly.")).split("/")[0];

  if (context?.req && context?.res) {
    // Server-side
    return setServerCookie(SPLIT_CONSUMER_COOKIE_NAME, encode({ splitKey }), {
      ...context,
      domain,
      expires: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
    });
  } else {
    // Client-side
    return setClientCookie(SPLIT_CONSUMER_COOKIE_NAME, encode({ splitKey }), {
      domain,
      expires: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
    });
  }
};
