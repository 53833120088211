"use client";

import { useEffect, useState } from "react";
import classNames from "classnames";
import Link from "next/link";
import { useSearchParams } from "next/navigation";

import { useCookieDomain } from "hooks/useCookieDomain";
import { setClientSideCookie } from "utils/cookies";
import { availableStorage } from "utils/storage";
import { getUserAgent, userAgentIsGoogleBot } from "utils/userAgent";

import { Popup } from "./Popup";

const COOKIE_SETTINGS_ADVERTISING = "acceptsAdvertisingCookies";
const VIEWED_COOKIE_CONSENT = "viewedCookieConsent";

// Cookie Banner is only based on local storage. Cookie is for backwards compatability.
const hasViewedBanner = () => {
  const storage = availableStorage();
  if (!storage) {
    return false;
  }

  return !!storage.getItem(VIEWED_COOKIE_CONSENT) || false;
};

export const CookiePopup = () => {
  // This logic is flipped to avoid a "flash" of the content
  const query = useSearchParams();
  const [hasNotViewedCookieBanner, setHasNotViewedCookieBanner] =
    useState(false);
  const cookieDomain = useCookieDomain();

  const acceptCookies = () => {
    const storage = availableStorage();
    if (storage) {
      storage.setItem(VIEWED_COOKIE_CONSENT, "true");
    }
    setClientSideCookie(COOKIE_SETTINGS_ADVERTISING, true, cookieDomain, 365);
    setHasNotViewedCookieBanner(false);
  };

  useEffect(() => {
    if (
      !userAgentIsGoogleBot(getUserAgent()) &&
      !query?.has("disable_cookie_popup")
    ) {
      setHasNotViewedCookieBanner(!hasViewedBanner());
    }
  }, []);

  return (
    <Popup
      onClose={acceptCookies}
      className={classNames(
        `fixed md:w-[300px]
         bottom-[0.5rem]  right-[0.5rem]  left-[0.5rem]
         md:bottom-[1rem] md:right-[1rem] md:left-[auto]`,
        {
          hidden: !hasNotViewedCookieBanner,
        },
      )}
      closeButtonLabel="Close privacy and cookie policy notice"
    >
      We use cookies for certain features and to improve your experience. See
      our <Link href="/info/privacy-policy#cookies">Cookie Policy</Link> and{" "}
      <Link href="/info/privacy-policy">Privacy Policy</Link> to learn more
    </Popup>
  );
};

export default CookiePopup;
