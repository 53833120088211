import React, { ForwardedRef } from "react";

import { Action, Category } from "constants/events";
import { useEventTracker } from "hooks/useEventTracker";
import { useDispatch, useSelector } from "redux/hooks";
import { REQUEST_LOCATION } from "redux/reducers/location";
import {
  getHasLoadedLocationCookie,
  getIndicatedAddress,
  getIsDefaultLocation,
} from "redux/selectors/location";

import LocationMarkerIcon from "components/Icons/location_marker.svg";

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: fix me please, do not replicate
export const LocationIndicator = React.forwardRef<HTMLButtonElement, any>(
  (_props, ref: ForwardedRef<HTMLButtonElement>) => {
    const hasLocationLoaded = useSelector(getHasLoadedLocationCookie);
    const indicatedAddress = useSelector(getIndicatedAddress);
    const isDefaultLocation = useSelector(getIsDefaultLocation);
    const dispatch = useDispatch();
    const { publishEvent } = useEventTracker();

    const indicatorText = !hasLocationLoaded
      ? indicatedAddress || "Locating..."
      : isDefaultLocation
        ? "Use current location"
        : indicatedAddress;

    return (
      <>
        <button
          data-testid="location_indicator"
          className="flex items-center text-xs header__location"
          aria-label={`Change location. ${
            hasLocationLoaded ? "Current location is " + indicatedAddress : ""
          }`}
          ref={ref}
          onClick={() => {
            publishEvent({
              action: Action.click,
              category: Category.header,
              label: "global nav location change",
            });
            dispatch({ type: REQUEST_LOCATION });
          }}
        >
          <LocationMarkerIcon width={24} height={24} id="location-icon" />
          <div className="hidden lg:flex items-center ml-xs overflow-hidden transition-[width,opacity] max-w-[34vw]">
            <div className="font-bold lg:font-normal text-green lg:text-default underline lg:no-underline truncate">
              {indicatorText}
            </div>
            <div className="hidden lg:block underline ml-sm">change</div>
          </div>
        </button>
      </>
    );
  },
);
