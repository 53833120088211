import axios from "axios";
import { getCookie, setCookie } from "cookies-next";
import { IncomingMessage, ServerResponse } from "http";
import pick from "lodash/pick";
import { GetServerSidePropsContext } from "next";

import publicConfig from "config/public";
import { Location } from "custom-types/Location";

import { GeolocationResult } from "./getGeolocation";

type OptionsType = Parameters<typeof getCookie>[1] | GetServerSidePropsContext;

export const FALLBACK_LOCATION: Location = {
  city: "Seattle",
  coordinates: { latitude: 47.6062095, longitude: -122.3320708 },
  country: "United States",
  countryCode: "US",
  country_code: "US",
  defaultLocation: true,
  formattedLocation: "Seattle, WA",
  formatted_location: "Seattle, WA",
  geoSlug: "seattle-wa-us",
  place_id: "ChIJVTPokywQkFQRmtVEaUZlJRA",
  slug: "seattle-wa-us",
  state: "Washington",
  state_code: "WA",
  street: { name: "", number: "" },
  sublocality: "",
  zip: "98164",
};

const {
  cookieDomain,
  cookieDomainCa,
  services: {
    geoIpApi: { url: geoIpApiUrl },
  },
} = publicConfig;

export const getUserLocationData = async (
  domainCountryCode: string,
  context?: Parameters<typeof getCookie>[1],
): Promise<Location> => {
  let location: Location | undefined = await getUserLocationByCookie(context);

  if (!location) {
    try {
      location = await getUserLocationByIp(context);
      if (location) {
        await setLocationCookie(
          { ...location, isUserLocation: true },
          domainCountryCode,
          context,
        );
      } else {
        throw new Error("failed to get user location");
      }
    } catch (e) {
      location = FALLBACK_LOCATION;
    }
  }

  return {
    ...location,
    countryCode: location.country_code,
    formattedLocation: location.formatted_location,
    geoSlug: location.slug,
  };
};

/**
 * This function loosely asserts that some value looks like it could be a
 * Location. Our current Location object is extremely permissive, so this
 * type predicate function only checks that the value is an object with
 * a subset of known properties being present in some form.
 *
 * Over time this validation should become more strict as we make the
 * Location type itself has fewer optional properties.
 *
 * See: https://www.typescriptlang.org/docs/handbook/2/narrowing.html#using-type-predicates
 */
const isLocation = (location: unknown): location is Location => {
  if (location === null || typeof location !== "object") {
    return false;
  }

  return Boolean(
    "coordinates" in location &&
      location.coordinates !== null &&
      typeof location.coordinates === "object" &&
      ("lat" in location.coordinates || "latitude" in location.coordinates) &&
      ("lon" in location.coordinates ||
        "longitude" in location.coordinates ||
        "lng" in location.coordinates),
  );
};

const getUserLocationByCookie = async (
  context?: OptionsType,
): Promise<Location | undefined> => {
  const locationCookie = await getCookie("leafly-location", context);

  try {
    const json = JSON.parse(String(locationCookie));

    if (isLocation(json)) {
      return json;
    } else {
      return undefined;
    }
  } catch {
    return undefined;
  }
};

const getUserLocationByIp = async (
  context?: OptionsType,
): Promise<Location | undefined> => {
  let ip;

  if (context) {
    ip = getUserIp(context);

    if (ip?.slice(0, 7) === "::ffff:") {
      ip = ip.slice(7);
    }
  }

  try {
    const response = await axios.get<GeolocationResult>(
      `${geoIpApiUrl}/geo/v1/geoip${ip ? `/${ip}` : ""}`,
    );

    if (response?.status === 200) {
      return pick(response.data, [
        "city",
        "coordinates",
        "country_code",
        "country",
        "formatted_location",
        "place_id",
        "slug",
        "state_code",
        "state",
        "street",
        "sublocality",
        "zip",
      ]);
    }
  } catch {
    return;
  }
};

const getUserIp = (context?: OptionsType): string | undefined => {
  const xForwardedFor = (context?.req as IncomingMessage)?.headers?.[
    "x-forwarded-for"
  ];

  if (xForwardedFor) {
    return String(xForwardedFor).split(",").pop();
  }

  return (context?.req as IncomingMessage)?.socket?.remoteAddress;
};

export const setLocationCookie = async (
  location: Location,
  domainCountryCode: string,
  context?: OptionsType,
): Promise<void> => {
  const domain = domainCountryCode === "CA" ? cookieDomainCa : cookieDomain;

  return setCookie("leafly-location", JSON.stringify(location), {
    domain,
    maxAge: 31536000000,
    path: "/",
    req: context?.req as IncomingMessage,
    res: context?.res as ServerResponse<IncomingMessage>,
  });
};
