import { getCookies } from "cookies-next/client";

export const LOAD_COOKIES = "LOAD_COOKIES";

export type LoadCookiesAction = {
  type: typeof LOAD_COOKIES;
  cookies: {
    [key: string]: string;
  };
};

// This is done to keep the reducers "pure" from side effects. Grabbing cookies should be done outside of pure functions.
export const createLoadCookiesAction = (): LoadCookiesAction => {
  return {
    cookies: getCookies() as {
      [key: string]: string;
    },
    type: "LOAD_COOKIES",
  };
};
