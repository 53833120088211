/**
 * This module provides type-safe alternatives to the hooks provided by
 * the `react-redux` library. These should always be used in preference
 * to the latter in order to ensure that events and data are emitted and
 * consumed correctly.
 */

import {
  TypedUseSelectorHook,
  // eslint-disable-next-line no-restricted-imports -- this is the only location that we should be importing react-redux hooks
  useDispatch as untypedUseDispatch,
  // eslint-disable-next-line no-restricted-imports -- this is the only location that we should be importing react-redux hooks
  useSelector as untypedUseSelector,
  // eslint-disable-next-line no-restricted-imports -- this is the only location that we should be importing react-redux hooks
  useStore as untypedUseStore,
} from "react-redux";

import { AppState, AppStore, AppThunkDispatch } from "./store";

/**
 * A hook to access the redux `dispatch` function.
 *
 * @returns {any|function} redux store's `dispatch` function
 *
 * @example
 *
 * import React, { useCallback } from 'react'
 * import { useDispatch } from 'react-redux'
 *
 * export const CounterComponent = ({ value }) => {
 *   const dispatch = useDispatch()
 *   const increaseCounter = useCallback(() => dispatch({ type: 'increase-counter' }), [])
 *   return (
 *     <div>
 *       <span>{value}</span>
 *       <button onClick={increaseCounter}>Increase counter</button>
 *     </div>
 *   )
 * }
 */
export const useDispatch: () => AppThunkDispatch = untypedUseDispatch;

/**
 * A hook to access the redux store's state. This hook takes a selector function
 * as an argument. The selector is called with the store state.
 *
 * This hook takes an optional equality comparison function as the second parameter
 * that allows you to customize the way the selected state is compared to determine
 * whether the component needs to be re-rendered.
 *
 * @param {Function} selector the selector function
 * @param {Function=} equalityFn the function that will be used to determine equality
 *
 * @returns {any} the selected state
 *
 * @example
 *
 * import React from 'react'
 * import { useSelector } from 'react-redux'
 *
 * export const CounterComponent = () => {
 *   const counter = useSelector(state => state.counter)
 *   return <div>{counter}</div>
 * }
 */
export const useSelector: TypedUseSelectorHook<AppState> = untypedUseSelector;

/**
 * A hook to access the redux store.
 *
 * @returns {any} the redux store
 *
 * @example
 *
 * import React from 'react'
 * import { useStore } from 'react-redux'
 *
 * export const ExampleComponent = () => {
 *   const store = useStore()
 *   return <div>{store.getState()}</div>
 * }
 */
export const useStore: () => AppStore = untypedUseStore;
