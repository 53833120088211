import {
  deleteCookie as deleteClientCookie,
  getCookie as getClientCookie,
  setCookie as setClientCookie,
} from "cookies-next/client";
import { setCookie as setServerCookie } from "cookies-next/server";
import { IncomingMessage, ServerResponse } from "http";

const cookiesEnabled = () => {
  return (
    typeof document !== "undefined" &&
    typeof navigator !== "undefined" &&
    navigator.cookieEnabled
  );
};

export const getCookie = (name: string): string | undefined => {
  if (cookiesEnabled()) {
    return getClientCookie(name);
  }
};

export const setClientSideCookie = (
  cookieName: string,
  value: string | boolean,
  domain: string,
  expires: number | Date = 365,
): void => {
  if (cookiesEnabled()) {
    setClientCookie(cookieName, value, {
      domain,
      ...(typeof expires === "number"
        ? { maxAge: expires * 24 * 60 * 60 }
        : { expires }),
    });
  }
};

export const removeCookie = (name: string, domain: string): void => {
  deleteClientCookie(name, { domain });
};

interface OptionsType {
  req?: IncomingMessage;
  res?: ServerResponse;
  maxAge?: number;
  path?: string;
  domain?: string;
  secure?: boolean;
  httpOnly?: boolean;
  sameSite?: boolean | "lax" | "strict" | "none";
}

export const setServerSideCookie = async (
  key: string,
  data: string,
  options: OptionsType = {},
) => {
  const { req, res, ...restOptions } = options;
  const ONE_YEAR_MS = 60 * 60 * 24 * 365 * 1000;
  const cookieOptions = {
    expires: new Date(Date.now() + ONE_YEAR_MS),
    maxAge: 60 * 60 * 24 * 365,
    path: "/",
    ...restOptions,
  };

  return setServerCookie(key, data, { req, res, ...cookieOptions });
};
